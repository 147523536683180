import React from 'react'



const ProductCard = ({ title, content, image }) => {
  return (
    <div className=" bg-[white] rounded-lg overflow-hidden shadow-sm p-3">
        <div className='bg-[white] mx-auto rounded-md flex justify-center items-center '>
        <img  src={image} alt={title} className=" object-cover mx-auto" />
        </div>
      <div className="pt-2.5 pb-4">
        <h3 className="text-[#6F9B0B] text-lg font-medium">{title}</h3>
        <h3 className="text-[black]">{content}</h3>
      </div>
    </div>
  )
}

export default ProductCard