import React from 'react'
import ProductCard from './ProductCard';
import { blog, blog1, blog2, blog3, leaf, leaf2, product1, product2, product3, product4 } from '../../../assets/images';

const products = [
  {
    id: 1,
    title: 'Huwasanın Tibb sahəsində İstifadəsi',
    content: 'Laboratoriyaların, cərrahi materialların və alətlərin dezinfeksiyası. (alətlərin yuyulması) və...',
    image: blog1, // Replace with the actual image path
    path: '/sofa'
  },
  {
    id: 2,
    title: 'Huwasanın Kənd Təsərrüfatı sektor...',
    content: 'Bitki suvarma suyunda patogen mikroorqanizmlərə və yosunlara qarşı mübarizədə...',
    image: blog2, // Replace with the actual image path
     path: '/top'
  },
  {
    id: 3,
    title: 'Huwasanın Heyvandarlıq və Quşçul...',
    content: 'Heyvan sığınacaqlarının, qəfəslərin və toyuq hinilərinin dezinfeksiyasında.; Heyvanları daşıy...',
    image: blog3, // Replace with the actual image path
    path:'/komfort',
    
  },
];

const Blogs = () => {
  return (
  <div className='bg-[#F1F1F1] mx-auto pb-10 pt-10 md:pb-20 md:pt-20'>
        <div className='mb-[10px] text-center'>
     <p className='text-[#6F9B0B] text-[22px] md:text-[32px] font-medium'>Blog</p>
     </div>
      <div className="mx-auto py-5 md:py-10 max-w-containerSm md:max-w-[1260px]">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
      {products.map((product) => (
        <a href={`/product-detail/${product.id}`}>
          <ProductCard
          key={product.id}
          title={product.title}
          content={product.content}
          image={product.image}
        />
        </a>
      ))}
    </div>
  </div>
  <a href='/blogs'> <p className='text-[#6F9B0B] mt-3 text-center'>Daha çox <i class="fa-solid fa-arrow-right"></i></p></a>
  </div>
  )
}

export default Blogs