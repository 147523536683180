import React from 'react'
import { blog1, blog2, blog3, blogBg, leaf2, whyus } from '../../assets/images'
import ProductCard from './ProductCard';
import PaginatedItems from './PaginatedItems'


const Blog = () => {

  const products = [
    {
      id: 1,
      title: 'Huwasanın Tibb sahəsində İstifadəsi',
      content: 'Laboratoriyaların, cərrahi materialların və alətlərin dezinfeksiyası. (alətlərin yuyulması) və...',
      image: blog1, // Replace with the actual image path
      path: '/sofa'
    },
    {
      id: 2,
      title: 'Huwasanın Kənd Təsərrüfatı sektor...',
      content: 'Bitki suvarma suyunda patogen mikroorqanizmlərə və yosunlara qarşı mübarizədə...',
      image: blog2, // Replace with the actual image path
       path: '/top'
    },
    {
      id: 3,
      title: 'Huwasanın Heyvandarlıq və Quşçul...',
      content: 'Heyvan sığınacaqlarının, qəfəslərin və toyuq hinilərinin dezinfeksiyasında.; Heyvanları daşıy...',
      image: blog3, // Replace with the actual image path
      path:'/komfort',
      
    },
    {
      id: 1,
      title: 'Huwasanın Tibb sahəsində İstifadəsi',
      content: 'Laboratoriyaların, cərrahi materialların və alətlərin dezinfeksiyası. (alətlərin yuyulması) və...',
      image: blog1, // Replace with the actual image path
      path: '/sofa'
    },
    {
      id: 2,
      title: 'Huwasanın Kənd Təsərrüfatı sektor...',
      content: 'Bitki suvarma suyunda patogen mikroorqanizmlərə və yosunlara qarşı mübarizədə...',
      image: blog2, // Replace with the actual image path
       path: '/top'
    },
    {
      id: 3,
      title: 'Huwasanın Heyvandarlıq və Quşçul...',
      content: 'Heyvan sığınacaqlarının, qəfəslərin və toyuq hinilərinin dezinfeksiyasında.; Heyvanları daşıy...',
      image: blog3, // Replace with the actual image path
      path:'/komfort',
      
    },
    {
      id: 1,
      title: 'Huwasanın Tibb sahəsində İstifadəsi',
      content: 'Laboratoriyaların, cərrahi materialların və alətlərin dezinfeksiyası. (alətlərin yuyulması) və...',
      image: blog1, // Replace with the actual image path
      path: '/sofa'
    },
    {
      id: 2,
      title: 'Huwasanın Kənd Təsərrüfatı sektor...',
      content: 'Bitki suvarma suyunda patogen mikroorqanizmlərə və yosunlara qarşı mübarizədə...',
      image: blog2, // Replace with the actual image path
       path: '/top'
    },
    {
      id: 3,
      title: 'Huwasanın Heyvandarlıq və Quşçul...',
      content: 'Heyvan sığınacaqlarının, qəfəslərin və toyuq hinilərinin dezinfeksiyasında.; Heyvanları daşıy...',
      image: blog3, // Replace with the actual image path
      path:'/komfort',
      
    },
  ];
  return (
   <>
     <div style={{height: window.innerWidth < 640 ? '120px' : '230px', backgroundImage: `url(${blogBg})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent:'center', alignItems:'end' }}>
     <div className='mb-[10px] md:mb-[44px] text-center'>
       <p className='text-[#6F9B0B] text-[32px] md:text-[40px] font-medium'>Bloq</p>
     </div>
   </div>
   <div className='bg-[#F1F1F1] mx-auto pb-20'>
      <div className="mx-auto py-10 max-w-containerSm md:max-w-[1260px]">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
      {products.map((product) => (
        <a href={`/blog-detail/${product.id}`}>
          <ProductCard
          key={product.id}
          title={product.title}
          content={product.content}
          image={product.image}
        />
        </a>
      ))}
    </div>
  </div>
  </div>
     
    </>
  )
}

export default Blog